
.App {
  text-align: center;
}

.App-logo {
  max-height: 10vw;
  max-width: 100px;
  pointer-events: none;
  filter: brightness(0) invert(1);
}

.app-container{
  background-image: url('./assets/background_1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

}

.overlay {
  background-color: rgba(0, 0, 0, 0.9); /* Dark overlay color with 50% opacity */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes fadeInLeftToRight {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInRightToLeft {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeBottomToTop {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
  
}


.fadeInLeftToRight {
  animation: fadeInLeftToRight 2s ease-out forwards;
}
.fadeInRightToLeft {
  animation: fadeInRightToLeft 2s ease-out forwards;
}
.fadeBottomToTop {
  animation: fadeBottomToTop 2s ease-out forwards;
}

.desktop-view {
  display: flex;
}

.mobile-view {
  display: none;
}

@media (max-width: 900px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: flex;
  }
}


/* Video slider styles */

.slick-slide {
  margin-right: 15px; /* Adjust the gap size as needed */
}

/* Optional: Adjust padding or margin of the carousel container if needed */
.playlist-carousel-container {
  padding: 0 15px; /* This might be necessary depending on your layout */
}