body {
  margin: 0;
  font-family: 'Neon Glow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body {
  font-family: 'Neon Glow', 'Pt Mono', sans-serif !important;
}
.code-element {
  font-family: 'PT Mono', monospace;
  font-weight:bold;
}

/* Font face for Neon Glow */
@font-face {
  font-family: 'Neon Glow';
  src: url('./assets/fonts/NEON GLOW.otf') format('opentype');
}

/* Neon Glow class */
.neon-glow {
  font-family: 'Neon Glow', sans-serif;
}